import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { RouterModule } from '@angular/router';
import { HomeRoutes } from 'src/app/modules/home/home.routing';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FormsModule } from '@angular/forms';
import { IntroductionComponent } from './components/introduction/introduction.component';
import { WerkenComponent } from 'src/app/modules/home/components/werken/werken.component';
import { IntroductionPageComponent } from './pages/introduction-page/introduction-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import {MatIconModule} from '@angular/material/icon';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CarouselModule, WavesModule } from 'angular-bootstrap-md'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [HomePageComponent, IntroductionComponent, WerkenComponent, IntroductionPageComponent, ContactPageComponent, ContactFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatIconModule,
    MatProgressSpinnerModule,
    LazyLoadImageModule,
    RouterModule.forChild(HomeRoutes),
    CarouselModule, WavesModule
    
  ],
  exports: [HomePageComponent, IntroductionPageComponent, ContactPageComponent]
})
export class HomeModule { }
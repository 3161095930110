import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutService } from 'src/app/modules/shared/services/layout.service';

@Component({
  selector: 'app-introduction-page',
  templateUrl: './introduction-page.component.html',
  styleUrls: ['./introduction-page.component.scss']
})
export class IntroductionPageComponent implements OnInit {

  isDesktop$: BehaviorSubject<boolean>;

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.isDesktop$ = this.layoutService.isDesktop$;

  }

}

<div class="about" [ngClass]="{ 'mobile' : !(isDesktop$ | async)}">
    <div class="introduction">
        <div class="header">
            <h1>Schrijnwerkerij STARCO</h1>
        </div>
        <div class="content">
            <div class="section">
                Wij zijn gespecialiseerd in het vervaardigen van artisanaal timmerwerk en buitenschrijnwerk op maat.
                Ons aanbod gaan van nieuwbouw tot renovatie in landelijke stijl, monumentale stijl, stadswoningen...
            </div>
            <div class="section">
                Daarbij kunnen wij u verschillende raam- en deurtypes, speciale dak- en gootafwerkingen, dakgebinten en houten bijgebouwen aanbieden.
            </div>
            <div class="section">
                STARCO gebruikt hout uit beheerde bossen waaronder het keurmerk FSC.
                FSC is een label of keurmerk op ene hout- of papierproduct dat aangeeft dat een product afkomstig is uit ene duurzaam beheerd bos.
                Het FSC label garandeert de duurzame oorsprong omdat het niet enkel het bos van oorsprong wordt gecontroleerd, maar ook de hele handelsketen tot
                bij de eindconsument. Als eindklant krijgt u dan de garantie: dit product is afkomstig uit een goed beheerd, FSC gecertificeerd bos.
            </div>
        </div>
    </div>
    <div class="contact">
        <p>Henk Remerie - 0477 49 85 69 - starco@telenet.be - Nazarethsesteenweg 40, 9770 Kruisem</p>
    </div>
</div>


import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutService } from 'src/app/modules/shared/services/layout.service';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent implements OnInit {

  isDesktop$: BehaviorSubject<boolean>;

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.isDesktop$ = this.layoutService.isDesktop$;

  }

}

import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { rootRouterConfig } from 'src/app/app.routing';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from "@angular/flex-layout";
import { HeaderComponent } from 'src/app/modules/shared/components/header/header.component';
import { FooterComponent } from 'src/app/modules/shared/components/footer/footer.component';
import { MatListModule } from "@angular/material/list";
import { LogoComponent } from 'src/app/modules/shared/components/header/logo/logo.component';
import { NavListComponent } from 'src/app/modules/shared/components/header/nav-list/nav-list.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [HeaderComponent, FooterComponent, LogoComponent, NavListComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatListModule,
        MatToolbarModule,
        MatSidenavModule,
        MatIconModule,
        BrowserAnimationsModule,
        BrowserModule
    ],
    exports: [
        FlexLayoutModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        HeaderComponent, 
        FooterComponent,
        HeaderComponent, 
        FooterComponent, 
        LogoComponent, 
        NavListComponent,
        MatToolbarModule,
        MatSidenavModule,
        MatIconModule,
        BrowserAnimationsModule,
        BrowserModule,
        MatListModule
    ],
})

export class SharedModule {}
<section id="home-page">
    <ng-container *ngIf="(isDesktop$ | async); else isMobile">
        <header class="header">
            <app-logo></app-logo>
            <app-nav-list (toggle)="changeWerk($event)"></app-nav-list>
        </header>
        <app-werken *ngIf="isDesktop$ | async" style="flex-grow: 1;" [currentWork]="currentWork"></app-werken>
    </ng-container>
    <ng-template #isMobile>
        <div>
            <mat-toolbar>
              <a (click)="snav.toggle()"><mat-icon>menu</mat-icon></a>
              <h1>Starco</h1>
            </mat-toolbar>
          
            <mat-sidenav-container>
              <mat-sidenav #snav>
                <mat-nav-list>
                  <app-nav-list (toggle)="changeWerk($event)" class="content"></app-nav-list>
                </mat-nav-list>
              </mat-sidenav>
              <mat-sidenav-content>
                <app-werken style="flex-grow: 1;" [currentWork]="currentWork"></app-werken>
              </mat-sidenav-content>
            </mat-sidenav-container>
          </div>
    </ng-template>
</section>



import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isDesktop$: BehaviorSubject<boolean>;
  currentWork: BehaviorSubject<string> = new BehaviorSubject("ramen");

  @Output() toggle: EventEmitter<string> = new EventEmitter();
  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.isDesktop$ = this.layoutService.isDesktop$;
  }

  changeWerk(werk: string){
    this.toggle.emit(werk);
  }

  

  

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LayoutService } from 'src/app/modules/shared/services/layout.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  @ViewChild(MatSidenav) sidenav: MatSidenav

  currentWork: BehaviorSubject<string> = new BehaviorSubject("ramen");
  isDesktop$: BehaviorSubject<boolean>;

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.isDesktop$ = this.layoutService.isDesktop$;
  }

  changeWerk(werk: string){
    if(this.sidenav){
      this.sidenav.close();
    }
    this.currentWork.next(werk);
  }

}

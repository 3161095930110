
<ng-container *ngIf="(isDesktop$ | async); else isMobile">
    <section class="image-roulette">

        <section class="primary" >
            <ng-container *ngIf="currentImage && !isDataloading; else Spinner">
                <img class="image-full" [lazyLoad]="currentImage.src"/>
            </ng-container>
        </section>
        <section class="secondary" id="style-1">
            <section *ngFor="let image of images; let idx = index; let even = even">
                <ng-container *ngIf="!isDataloading; else Spinner">
                    <img class="image-small" [defaultImage]="image.thumbImage" [lazyLoad]="image.src" (click)="showNewImage(idx)"/>
                </ng-container>
            </section>
        </section>
    </section>

</ng-container>

<ng-template #isMobile>
    <section class="image-roulette">
        <section class="primary mobile" *ngIf="currentImage">
            <img class="image-full" [defaultImage]="currentImage.thumbImage" [lazyLoad]="currentImage.src"/>
        </section>
        <section class="secondary mobile" id="style-1">
            <section *ngFor="let image of images; let idx = index; let even = even">
                <img class="image-small" [defaultImage]="image.thumbImage" [lazyLoad]="image.src" (click)="showNewImage(idx)"/>
            </section>
        </section>
    </section>
</ng-template>

<ng-template #Spinner>
    <div class="uploader-status">
        <mat-progress-spinner [color]="'red'" mode="indeterminate"></mat-progress-spinner>
    </div>
</ng-template>
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss']
})
export class NavListComponent implements OnInit {

  @Output() toggle: EventEmitter<string> = new EventEmitter();
  public selected = 2;
  constructor() { }

  ngOnInit(): void {
  }

  changeWerk(werk: string, selected: number){
    this.selected = selected;
    this.toggle.emit(werk);
  }

}

import { Routes } from '@angular/router';
import { HomePageComponent } from 'src/app/modules/home/pages/home-page/home-page.component';
import { AppComponent } from 'src/app/app.component';


export const rootRouterConfig: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomePageComponent,
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
    }
]
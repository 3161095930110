import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { rootRouterConfig } from './app.routing';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { HomeModule } from 'src/app/modules/home/home.module';
import { HttpClientModule } from '@angular/common/http';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LayoutService } from './modules/shared/services/layout.service';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(rootRouterConfig, {useHash: false, enableTracing: false}),
    RouterModule,
    SharedModule,
    HomeModule,
    HttpClientModule,
    LazyLoadImageModule,
    // Firebase
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    MDBBootstrapModule.forRoot()
  ],
  providers: [LayoutService],
  bootstrap: [AppComponent],
})
export class AppModule { }

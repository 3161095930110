<mat-nav-list>
    <mat-list-item>
        <a (click)="changeWerk('deuren', 1)" [style.color]="selected== 1 ? '#efd8b9' : 'white'"><div>Buitendeuren</div></a>
    </mat-list-item>
    <mat-list-item>
        <a (click)="changeWerk('ramen', 2)" [style.color]="selected== 2 ? '#efd8b9' : 'white'"><div>Ramen</div></a>
    </mat-list-item>
    <mat-list-item>
        <a (click)="changeWerk('dakconstructies', 3)" [style.color]="selected== 3 ? '#efd8b9' : 'white'"><div>Dakconstructies</div></a>
    </mat-list-item>  
    <mat-list-item>
        <a (click)="changeWerk('renovaties', 4)" [style.color]="selected== 4 ? '#efd8b9' : 'white'"><div>Renovaties</div></a>
    </mat-list-item>
    <mat-list-item>
        <a (click)="changeWerk('realisaties', 5)" [style.color]="selected== 5 ? '#efd8b9' : 'white'"><div>Realisaties</div></a>
    </mat-list-item>
</mat-nav-list>

import { Injectable } from "@angular/core";
import { MediaObserver, MediaChange } from "@angular/flex-layout";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
  })

export class LayoutService {

    activeMediaQuery: string;
    isDesktop$ = new BehaviorSubject<boolean>(false);
    mediaChanged$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(private mediaObserver: MediaObserver) {
        this.activeMediaQuery = '';
    
        // tslint:disable-next-line: deprecation
        this.mediaObserver.media$.subscribe((change: MediaChange) => {
          if (this.activeMediaQuery !== change.mqAlias) {
            this.activeMediaQuery = change.mqAlias;
            if (this.mediaObserver.isActive('xs') || this.mediaObserver.isActive('sm')) {
              this.isDesktop$.next(false);
            } else if (this.mediaObserver.isActive('gt-sm')) {
              this.isDesktop$.next(true);
            }
            this.mediaChanged$.next(change.mqAlias);
          }
        });
      }
}

<ng-container *ngIf="(isDesktop$ | async); else isMobile">
    <section class="introduction-page">
        <app-introduction></app-introduction>
        <img class="starco-upscale1" src="../../../../../assets/images/UpscaledStarco1.png"/>
    </section>
</ng-container>
<ng-template #isMobile>
    <section class="introduction-page">
        <app-introduction></app-introduction>
    </section>
</ng-template>
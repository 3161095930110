import { Component, OnInit, Input } from '@angular/core';
import { StarcoImage } from 'src/app/core/models/starco-image';
import { Subscription } from 'rxjs';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/storage';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/app/modules/shared/services/layout.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-werken',
  templateUrl: './werken.component.html',
  styleUrls: ['./werken.component.scss']
})
export class WerkenComponent implements OnInit {


  @Input() currentWork: BehaviorSubject<string>;

  images: StarcoImage[] = [];
  currentImage: StarcoImage = null;
  subscriptions: Subscription[] = [];
  first: boolean = true;
  lastwork: string;
  isDesktop$: BehaviorSubject<boolean>;
  isDataloading: boolean = false;

  constructor( 
    private afStorage: AngularFireStorage, 
    private layoutService: LayoutService,
    private db: AngularFirestore) { 
  }

  ngOnInit(): void {
    this.isDesktop$ = this.layoutService.isDesktop$;
    this.downloadImages();
  
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  
  showNewImage(index: number){
    this.currentImage = this.images[index];
  }

  testDownload(){
  }

  downloadImages(){
    this.isDataloading = true;
    this.subscriptions.push(this.currentWork.subscribe((work: string) => {
      if(work != this.lastwork){
        this.images = [];
        this.currentImage = null;
        this.lastwork = work;
        this.first = true;
        const ref: AngularFireStorageReference = this.afStorage.ref(work);
        this.subscriptions.push(
          ref.listAll().subscribe((result) => {
            result.items.map(item => {
              var image:StarcoImage = {
                src: environment.urlEndpoint + '/' + item.fullPath,
                thumbImage: environment.urlEndpoint + '/tr:w-100,h-150,pr-true/' + item.fullPath,
              }
              if(this.first){
                this.currentImage = image;
                this.first = !this.first;
              }
              this.images.push(image)
            })
          }, () => this.isDataloading = false, () => this.isDataloading = false)
        );
      }
    }));
  }
}
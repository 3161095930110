// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  publicKey: "public_HUhzwkhEEWwvK64TNx37KzUxzMc=",
  urlEndpoint: 'https://ik.imagekit.io/jlqw2ljyixj',
  firebaseConfig: {
    apiKey: "AIzaSyBsnqbm_-SGuEktlEpzPA3HaRPDZai-TMU",
    authDomain: "starco-d1040.firebaseapp.com",
    databaseURL: "https://starco-d1040.firebaseio.com",
    projectId: "starco-d1040",
    storageBucket: "starco-d1040.appspot.com",
    messagingSenderId: "1061595370486",
    appId: "1:1061595370486:web:6ae67154bd4a68dbb82dd7",
    measurementId: "G-3FYS46P0E5"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
